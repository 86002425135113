@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family:
    "whyte-inktrap",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  min-width: 320px;
}

body strong {
  font-weight: 600;
}

body #modal-root {
  opacity: 0;
  backdrop-filter: blur(0);
  transform: translateX(-99999999px);
  transition:
    opacity ease 200ms,
    backdrop-filter ease 200ms;
  z-index: 50;
}

body.modal-open {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

body.modal-open #modal-root {
  transform: translateX(0);
  backdrop-filter: blur(10px);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

body #icloud-return-burger-menu-root {
  background: white;
  position: fixed;
  top: 70px;
  left: 0;
  opacity: 0;
  height: calc(100vh - 70px);
  width: 100vw;
  transform: translateX(-100vw);
  z-index: 50;
}

@media only screen and (min-width: 1048px) {
  body #icloud-return-burger-menu-root {
    display: none;
  }
}

body #burger-menu-root {
  background: #0e0075;
  position: fixed;
  top: 72px;
  left: 0;
  opacity: 0;
  height: calc(100vh - 72px);
  width: 100vw;
  transform: translateX(-100vw);
  z-index: 50;
}

@media only screen and (min-width: 1048px) {
  body #burger-menu-root {
    display: none;
  }
}

body.burger-menu-open {
  overflow: hidden;
}

body.burger-menu-open #burger-menu-root {
  transition: all ease 200ms;
  transform: translateX(0);
  opacity: 1;
}

body.icloud-return-burger-menu-open {
  overflow: hidden;
}

body.icloud-return-burger-menu-open #icloud-return-burger-menu-root {
  transition: all ease 200ms;
  transform: translateX(0);
  opacity: 1;
}

body #video-lightbox-root {
  opacity: 0;
  backdrop-filter: blur(0);
  transform: translateX(-99999999px);
  transition:
    opacity ease 200ms,
    backdrop-filter ease 200ms;
  z-index: 50;
}

body.video-lightbox-open {
  overflow: hidden;
}

body.video-lightbox-open #video-lightbox-root {
  opacity: 1;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.8);
  transform: translateX(0);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#__next {
  height: 100%;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

.tooltip {
  background: #0d0256 !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 0.75rem !important;
  line-height: 1.4 !important;
  padding: 0.75em !important;
  text-align: center !important;
  box-shadow:
    0 1px 1px 0 rgba(13, 2, 86, 0.1),
    0 2px 2px 0 rgba(13, 2, 86, 0.1),
    0 4px 4px 0 rgba(13, 2, 86, 0.1),
    0 8px 8px 0 rgba(13, 2, 86, 0.1) !important;
  max-width: 250px;
}

.tooltip.place-top::after {
  border-top-color: #0d0256 !important;
}

.tooltip-pink {
  background: rgba(255, 115, 176, 0.9) !important;
  box-shadow:
    0 33px 80px rgba(166, 0, 73, 0.28),
    0 9.94853px 25.7781px rgba(166, 0, 73, 0.182445),
    0 4.13211px 12.3925px rgba(166, 0, 73, 0.14),
    0 1.4945px 5.27683px rgba(166, 0, 73, 0.0975551) !important;
  border-radius: 16px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  padding: 12px !important;
  text-align: center !important;
  max-width: 272px;
}

.tooltip-pink.place-bottom::after {
  top: -7px !important;
  border-bottom-color: rgba(255, 115, 176, 0.95) !important;
}

.tooltip-pink.place-top::after {
  bottom: -7px !important;
  border-top-color: rgba(255, 115, 176, 0.95) !important;
}

.hide-link {
  text-indent: -999999px;
  line-height: 0;
  font-size: 0;
  color: transparent;
}

/* Hide scrollbar */

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;

  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
}

.adyen-container img {
  margin: auto;
}

.adyen-container .adyen-checkout__threeds2__challenge {
  min-height: 600px !important;
}

/* Newsletter modal */

.newsletter-modal-overlay {
  backdrop-filter: blur(0);
  transition: backdrop-filter ease 500ms;
}

.newsletter-modal-overlay--after-open {
  backdrop-filter: blur(3px);
}

.newsletter-modal-content {
  bottom: -25px;
  animation: slide-up 0.5s forwards;
}

@keyframes slide-up {
  to {
    bottom: 0;
  }
}

.press-carousel .paging-dot {
  width: 8px;
  height: 8px;
}

.press-carousel .paging-item {
  opacity: 1 !important;
}

.press-carousel .paging-item.active {
  fill: white !important;
  border: 1.5px solid #000c17 !important;
  border-radius: 50% !important;
}

.press-carousel .paging-item.active > .paging-dot {
  width: 6px;
  height: 6px;
}

.press-carousel .paging-item:not(.active) > .paging-dot {
  fill: #b8b32f;
}

/* Product images carousel */
.product-images-carousel .paging-dot {
  width: 8px;
  height: 8px;
}

.product-images-carousel .paging-item {
  opacity: 1 !important;
  border: 1.5px solid black !important;
  border-radius: 50% !important;
}

.product-images-carousel .paging-item:not(.active) > .paging-dot {
  fill: transparent;
}

/* https://stackoverflow.com/questions/77933841/how-to-remove-marker-from-details-element-on-safari */
details summary::-webkit-details-marker {
  display: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Reposition helpscout beacon in the funnel */
.BeaconFabButtonFrame {
  bottom: 60px !important;
}
