@font-face {
  font-family: boing;
  src:
    url("/fonts/boing_regular.woff2") format("woff2"),
    url("/fonts/boing_regular.woff") format("woff"),
    url("/fonts/boing_regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: boing;
  src:
    url("/fonts/boing_medium.woff2") format("woff2"),
    url("/fonts/boing_medium.woff") format("woff"),
    url("/fonts/boing_medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: boing;
  src:
    url("/fonts/boing_semibold.woff2") format("woff2"),
    url("/fonts/boing_semibold.woff") format("woff"),
    url("/fonts/boing_semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: boing;
  src:
    url("/fonts/boing_bold.woff2") format("woff2"),
    url("/fonts/boing_bold.woff") format("woff"),
    url("/fonts/boing_bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: whyte-inktrap;
  src: url("/fonts/whyte-inktrap/WhyteInktrap-Light.woff2") format("opentype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: whyte-inktrap;
  src: url("/fonts/whyte-inktrap/WhyteInktrap-Regular.woff2") format("opentype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: whyte-inktrap;
  src: url("/fonts/whyte-inktrap/WhyteInktrap-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: whyte-inktrap;
  src: url("/fonts/whyte-inktrap/WhyteInktrap-Bold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: whyte-inktrap;
  src: url("/fonts/whyte-inktrap/WhyteInktrap-Heavy.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
